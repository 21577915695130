exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-beready-privacy-policy-tsx": () => import("./../../../src/pages/beready/privacy_policy.tsx" /* webpackChunkName: "component---src-pages-beready-privacy-policy-tsx" */),
  "component---src-pages-blogs-welcome-tsx": () => import("./../../../src/pages/blogs/welcome.tsx" /* webpackChunkName: "component---src-pages-blogs-welcome-tsx" */),
  "component---src-pages-comingsoon-tsx": () => import("./../../../src/pages/comingsoon.tsx" /* webpackChunkName: "component---src-pages-comingsoon-tsx" */),
  "component---src-pages-common-page-template-tsx": () => import("./../../../src/pages/common/page_template.tsx" /* webpackChunkName: "component---src-pages-common-page-template-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-swoop-privacy-policy-tsx": () => import("./../../../src/pages/swoop/privacy_policy.tsx" /* webpackChunkName: "component---src-pages-swoop-privacy-policy-tsx" */)
}

